<template>
  <div class="page-rooter-fff">
    <nav-bar headerTitle="案例制作指南" :showBack="true" :header-back="appBack"></nav-bar>
    <app-page>
      <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach/1.jpg" alt="">
      <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach/2.jpg" alt="">
      <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/coach/3.jpg" alt="">
    </app-page>


  </div>
</template>

<script>
import appMixin from '@/mixin/appMixin'
import {hideAppBackBtn, appBack} from "@/lib/appMethod";
import navBar from '@/components/nav-bar/nav-bar'
import appPage from '@/common/components/appPage'
export default {
  data() {
    return {

    };
  },
  mixins: [appMixin],
  components: {
    navBar,
    appPage,
  },
  created() {

  },
  methods: {
    appBack() {
      appBack();
    },
  },
};
</script>

<style lang="less" scoped>
  .page-rooter-fff {
    img {
      width: 100%;
      display: block;
    }
  }
</style>
